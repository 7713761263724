import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SaveProfile from '@/mixins/SaveProfile';
export default {
  mixins: [SaveProfile],
  computed: {
    profileCountryName: function profileCountryName() {
      var _this = this;

      var country = this.profile.countries.find(function (c) {
        return c.id === _this.profile.countryId;
      });
      if (!country) return null;
      return country.name;
    }
  }
};