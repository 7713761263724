//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  computed: {
    profile: function profile() {
      return this.$store.getters['player/profile'];
    },
    currentLevel: function currentLevel() {
      return this.$store.getters['player/currentLevel'];
    }
  }
};