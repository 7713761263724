//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
import '@/components/icons/profile';
import { preFetchPage } from '@/helpers/router';
import ProfileAccount from '@/components/Profile/ProfileAccount';
import ProfileInfo from '@/components/Profile/ProfileInfo';
import ProfileSubscription from '@/components/Profile/ProfileSubscription';
import ProfileSecurity from '@/components/Profile/ProfileSecurity';
import ReturnToHomepage from '@/components/Controls/ReturnToHomepage';
export default {
  name: 'PageProfile',
  mixins: [Base],
  components: {
    'app-profile-account': ProfileAccount,
    'app-profile-info': ProfileInfo,
    'app-profile-subscription': ProfileSubscription,
    'app-profile-security': ProfileSecurity,
    ReturnToHomepage: ReturnToHomepage
  },
  data: function data() {
    return {
      contactsInfo: this.desktop,
      subscriptions: this.desktop,
      security: this.desktop
    };
  },
  props: {
    config: Object
  },
  preFetch: preFetchPage,
  meta: function meta() {
    var _this$seoData, _this$seoData2, _this$seoData3;

    return {
      title: ((_this$seoData = this.seoData) === null || _this$seoData === void 0 ? void 0 : _this$seoData.seoTitle) || 'PiasterCreek',
      meta: {
        description: {
          name: 'description',
          content: (_this$seoData2 = this.seoData) === null || _this$seoData2 === void 0 ? void 0 : _this$seoData2.seoDescription
        },
        keywords: {
          name: 'keywords',
          content: ((_this$seoData3 = this.seoData) === null || _this$seoData3 === void 0 ? void 0 : _this$seoData3.seoKeywords) || ''
        }
      }
    };
  },
  computed: {
    seoData: function seoData() {
      return this.$store.getters['statics/seoData'];
    },
    profile: function profile() {
      return this.$store.getters['player/profile'];
    },
    sections: function sections() {
      return this.$store.getters['sidebar/pageSections'];
    }
  },
  methods: {
    toggleSection: function toggleSection(section) {
      if (this.isDesktop) return;
      this[section] = !this[section];
    },
    getClass: function getClass(section) {
      if (this[section]) return 'is-open';
      return '';
    },
    setSectionsState: function setSectionsState() {
      this.contactsInfo = this.isDesktop;
      this.subscriptions = this.isDesktop;
      this.security = true;
    }
  },
  watch: {
    isDesktop: function isDesktop() {
      this.setSectionsState();
    }
  },
  created: function created() {
    this.setSectionsState();
  }
};