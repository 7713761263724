import { render, staticRenderFns } from "./ProfileInfoConfirmation.vue?vue&type=template&id=16e06f21&scoped=true&lang=pug&"
import script from "./ProfileInfoConfirmation.vue?vue&type=script&lang=js&"
export * from "./ProfileInfoConfirmation.vue?vue&type=script&lang=js&"
import style0 from "./ProfileInfoConfirmation.vue?vue&type=style&index=0&id=16e06f21&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e06f21",
  null
  
)

export default component.exports