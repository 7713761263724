import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=27047d8e&scoped=true&lang=pug&"
import script from "./Profile.vue?vue&type=script&lang=js&"
export * from "./Profile.vue?vue&type=script&lang=js&"
import style0 from "./Profile.vue?vue&type=style&index=0&id=27047d8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27047d8e",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QSlideTransition});
