//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import SaveProfile from '@/mixins/SaveProfile';
import verifyMixin from "./verifyMixin";
import ProfileMain from "./ProfileInfoMain";
import ProfilePlace from "./ProfileInfoPlace";
import ProfileVerification from "./ProfileInfoVerification";
import ProfileConfirmation from "./ProfileInfoConfirmation";
export default {
  components: {
    'app-profile-main': ProfileMain,
    'app-profile-place': ProfilePlace,
    'app-profile-verification': ProfileVerification,
    'app-profile-confirmation': ProfileConfirmation
  },
  mixins: [verifyMixin, SaveProfile],
  props: {
    open: Boolean
  },
  data: function data() {
    return {
      verify: null,
      isEnd: false,
      isBeginning: true,
      needSlide: false,
      swiperOption: {
        lazy: true,
        slidesPerView: 1,
        updateOnWindowResize: true,
        spaceBetween: 135,
        allowTouchMove: false,
        noSwiping: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
            allowTouchMove: true,
            noSwiping: false
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 1,
            spaceBetween: 60,
            allowTouchMove: false,
            noSwiping: true
          },
          1440: {
            slidesPerView: 1,
            spaceBetween: 135,
            allowTouchMove: false,
            noSwiping: true
          }
        }
      }
    };
  },
  methods: {
    showConfirmSlide: function showConfirmSlide(value) {
      var _this = this;

      this.verify = value;
      this.myswiper.update();
      Vue.nextTick(function () {
        return _this.slideNext();
      });
    },
    confirmedCode: function confirmedCode() {
      this.verify = null;
    },
    updateSlider: function updateSlider() {
      this.isEnd = this.myswiper.isEnd;
      this.isBeginning = this.myswiper.isBeginning;
      if (this.needSlide) this.needSlide = false;
    },
    removeVerifySlide: function removeVerifySlide() {
      var _this2 = this;

      if (this.verify) {
        this.verify = null;
        Vue.nextTick(function () {
          _this2.updateSlider();
        });
      }
    },
    slideNext: function slideNext() {
      this.myswiper.el.classList.remove('sliding-prev');
      this.myswiper.slideNext();
    },
    slidePrev: function slidePrev() {
      this.myswiper.el.classList.add('sliding-prev');
      this.removeVerifySlide();
      this.myswiper.slidePrev();
    },
    slideTo: function slideTo(slideIndex) {
      this.myswiper.slideTo(slideIndex);
    },
    checkProfileData: function checkProfileData() {
      var _this3 = this;

      if (!this.profileMainCompleted && !this.profileAddresCompleted) return;
      if (!this.profileMainCompleted) this.needSlide = 1;
      if (!this.profileAddresCompleted) this.needSlide = 2;
      if (this.needSlide) setTimeout(function () {
        _this3.slideTo(_this3.needSlide - 1);
      }, 3000);
    }
  },
  watch: {
    profileMainCompleted: function profileMainCompleted() {
      if (!this.profileAddresCompleted) this.slideTo(1);
    },
    profileAddresCompleted: function profileAddresCompleted() {
      if (!this.profileMainCompleted) this.slideTo(0);
    },
    open: function open() {
      this.myswiper.update();
    }
  },
  mounted: function mounted() {
    this.myswiper.on('slideChange', this.updateSlider);
    this.myswiper.on('slidePrevTransitionEnd', this.removeVerifySlide);
    this.checkProfileData();
  },
  beforeDestroy: function beforeDestroy() {
    this.myswiper.destroy();
  }
};